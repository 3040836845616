import React from 'react';
import { Link } from 'react-router-dom';
import { middleStyle } from '../../../../constants';
import styles from './index.styles';

const bannerBg = require('../../../../assets/products-banner.jpg');

const Banner = () => {
	return (
		<div
			style={{
				...styles.container,
				background: `url(${bannerBg}) 0 0 no-repeat`,
				backgroundSize: '100% 100%',
			}}
		>
			<div style={middleStyle}>
				<div style={styles.left}>
					<h1 style={styles.title}>
						Time to Take Control of Your Data Security
					</h1>
					<p style={styles.desc}>
						Android OS opportunities are infinite. But so are the
						vulnerabilities. FoneSafe is available to ensure that you get to
						enjoy all the available Android possibilities safely and for a long
						time.
					</p>
					<Link to="/pricing">
						<button style={styles.button}>Download</button>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default Banner;
