import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import styles from "./index.styles";
import Banner from "./components/Banner";
import { middleStyle } from "../../constants";
import Content from "./components/Content";

const title = "Contact Us";

const content = [
  {
    title: "",
    desc: [
      <>Want to talk to us?</>,
      <>If you have any questions, comments, or suggestions, please send them to <a href='mailto:support@fonesafe.net' className="text-purple hover:underline">support@fonesafe.net</a></>,
      <>A 24/7 team from FoneSafe will get back to you as soon as possible.</>,
      <>Check out our <a href="/faq" className="text-purple hover:underline">FAQs</a> section while waiting for our reply.</>,
    ],
  },
];

const ContactUs = () => {
  return (
    <div style={styles.container}>
      <Header />
      <Banner text={title} />
      <div style={{ ...middleStyle, ...styles.content }}>
        {content.map((data, index) => (
          <Content {...data} key={index} />
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
