import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import styles from "./index.styles";
import Banner from "./components/Banner";
import { middleStyle } from "../../constants";
import Content from "./components/Content";

const title = "End User License Agreement";

const content = [
  {
    title: "",
    desc: [
      "Please carefully read this End User License Agreement (“EULA”) prior to purchasing, installing or using the FoneSafe application software (hereinafter - the “Software”). By purchasing, downloading, installing or otherwise using the Software, you agree to be bound by and adhere to the terms of this EULA.",
      "If you do not accept the terms of this EULA in full, do not install or use the Software and delete the Software and all of its related files from your device(s).",
      "This EULA is a legal agreement between you (“you” or “user”) and FoneSafe, the software provider, for use of the Software. This EULA shall not be assigned or transferred in any manner by you without the prior written consent of FoneSafe.",
      "This EULA is an integral part of our Terms of Service, the provisions of which are as well applicable to your use of the Software.",
      "Our collection and use of personal data in connection with your access to and use of the Software is described in our Privacy Notice.",
      "If you have any questions or comments about this EULA or the Software, please contact us at support@fonesafe.net.",
    ],
  },
  {
    title: "Scope of License",
    desc: [
      "FoneSafe grants you a limited, non-exclusive, non-transferable revocable license (the “License”) to:",
      "install the Software on one or more devices, which are owned by you, or are under your control, and",
      "view, review and utilize the Software and any related information provided to you by the Software in accordance with this EULA and Terms of Service.",
      "Nothing in this EULA should be interpreted as permitting installation of the Software on any device you do not own or control, or which you do not have the legal right to monitor, or to monitor any account, application, or program you do not have the legal right to monitor. It is your responsibility to determine whether you have the legal right to monitor the device, account, application, or program. You may not distribute or make the Software available over a network where it could be used by multiple devices at the same time.",
      "Software ownership. It is understood and agreed that FoneSafe is the owner of all right, title, and interest in and to the original, and any copies of the Software and related information, improvements, enhancements or derivatives thereto and ownership of all intellectual property rights pertaining thereto, in whole or in part, shall be, vest with, and remain the exclusive property of FoneSafe.",
      "You, as licensee, through your downloading, installing, or use of the Software do not acquire any ownership rights to the Software. The Software is protected by copyright laws and international copyright treaties, as well as other intellectual property laws and treaties. The rights granted in this license are limited to the right to download, install, and use the Software and do not include any other intellectual property rights.",
      "You agree that you may not and will not:",
      "sell, lease, rent, license, sublicense, redistribute, assign or grant the Software;",
      "decompile, disassemble, or reverse engineer the Software, in whole or in part;",
      "write or develop any derivative software, make attempt to derive the source code of, modify, or create derivative works of the Software, any updates, or any part or any other software program based upon the Software;",
      "provide, disclose, divulge or make available to, or permit use of the Software by any third party without FoneSafe prior written consent; or",
      "copy the Software.",
      "Any attempt to do so is considered as a violation of the EULA and the rights of FoneSafe. If you breach this restriction, you may be subject to legal actions and damages.",
      "Software Modifications. FoneSafe may provide upgrades, modifications, updates, or additions (the “Modifications”) to the Software during the term of the License. The terms of this EULA shall be applied to any such Modification unless such Modification is accompanied by a separate license, in which case the terms of that license will be applied.",
      "You represent that participation under this EULA will not breach any agreement to keep in confidence proprietary information acquired by you in confidence or in trust prior to this EULA. You represent that you have not entered into, and will not enter into, any oral or written agreement in conflict herewith.",
    ],
  },
  {
    title: "Your representations",
    desc: [
      "User understands that the Software shall be used only with the aim of (i) parental control of their children, (ii) on a device, which is of a user’s ownership, (iv) by a user with a consent of a device owner. User acknowledges that the Software shall be used for the purpose of monitoring, tracking and obtaining access to certain devices as cell phone and computer (including, but not limited to, email and text messages) of children and other device owners with their consent hereto, including through the use of devices, on which the Software is installed.",
      "Device owner’s consent. By accepting this EULA, you agree to install this software ONLY on a device that is owned by you or on a device for which you have been given explicit permission and/or consent by the device owner for such installation.",
      "You further agree to use the Software only in connection with an Account you have the legal right to access. You also agree to inform any person(s), who use(s) a device with the Software installed. You agree that FoneSafe is not responsible or liable in any manner for the installation by you of the Software to a target device.",
      "Access to the target device.User acknowledges that the physical access to a target device is required for the Software to be installed on that target device. User acknowledges that the Software may not be installed remotely without possession bona fide of a target device by the User with physical access hereto.",
      "Device compatibility. User is aware that before installing the Software you have to check the requirements of compatibility of your target device according to the Compatibility Policy. In case you have failed to comply with Compatibility Policy after the installation you agree that it is your responsibility and you will not be refunded.",
      "Legal use. FoneSafe does not control, monitor and/or is not aware of the purpose a user exploits the Software application and FoneSafe may not be held responsible for such use thereof. It is your responsibility to ensure that your use of the Software does not contravene applicable laws, rules, or regulations.",
      "We make no representation that the Software or related materials are appropriate or available for use in your location, and the Software may not be accessed from territories where the content is prohibited by local laws. Those who choose to access the Software from such locations do so on their own initiative and are responsible for compliance with local laws, if and to the extent local laws are applicable. Any offer and/or information made in connection with the Software is void where prohibited.",
      "You should consult your own legal advisor with respect to legality of using the Software in the manner you intend to use it prior to downloading, installing, or using the Software. You take full responsibility for determining that you have the right to monitor the device, account, application, or program on which the Software is installed. FoneSafe shall not be held responsible if the User chooses to monitor a device, account, application, or program the User does not have the right to monitor.",
      "Data security. User consents not to allow any other person to access the information that the Software collects from the device, account, application, or program, on which the Software is installed.",
    ],
  },
  {
    title: "Prohibited Use of the Software",
    desc: [
      "You agree not to use the Software in any manner to harass, abuse, stalk, threaten, defame or otherwise infringe or violate the rights of any other person, and that FoneSafe is not in any way responsible for any such use by you, nor for any harassing, threatening, defamatory, offensive or illegal messages or transmissions that you may receive as a result of using the Software.",
      "In case the Software is installed on the device without permission of the owner, FoneSafe has the right to open un-installation instructions for this person and make sure that the account of a violator is blocked. Please be informed that all the data on FoneSafe servers is encrypted to protect the privacy of the account holder, therefore FoneSafe can neither disclose this data to any third parties nor read it from the server side.",
      "You agree that you will not at any time prior to or after this EULA, improperly use or disclose any proprietary information or trade secrets of any other persons which may be in your possession, if any. Further, should FoneSafe disclose confidential information of its third party licensors, you will comply with any use and confidentiality guidelines requested of you by FoneSafe. You will hold all such confidential and proprietary information in the strictest confidence and will not disclose it to any third party or use it for the benefit of any person other than for FoneSafe or such third party.",
    ],
  },
  {
    title: "Limited Warranty",
    desc: [
      "This limited non-transferable warranty covers the Software for the period under your Subscription plan after the Software is purchased. If you receive Software Modifications during that time, the limited warranty will be prolonged until the end of your Subscription plan.",
      "Exclusions from Warranty. This Limited Warranty does not cover problems caused by your acts (or failures to act), the acts of others, or events beyond FoneSafe’s reasonable control.",
      "Technical issues. In the event of technical issues with the Software you may address professional help from FoneSafe to support@fonesafe.net, which will provide you with the full support under the limited warranty during your Subscription plan.",
      "Remedy. In case you have technical issues with the Software, which can not be fixed by FoneSafe, the sole remedy you are eligible for is a full refund in accordance with the Refund Policy.",
      "To exercise your Limited Warranty, please send an email with description of the problem and proof of License purchase to support@fonesafe.net.",
    ],
  },
  {
    title: "Limitations of liability",
    desc: [
      "To the extent permitted by the applicable law, FoneSafe shall not be liable to you in respect of any claim, demand or action, irrespective of the nature of the cause of the claim, demand or action alleging any loss, injury or damages, direct or indirect, which may result from use or possession of the Software; or for any loss of profit, revenue, data, contracts or savings, or any other direct, indirect, incidental, special or consequential damages arising out of your use of or inability to use the software, any defect in the data, or the breach of this eula, whether in an action in contract or tort or based on a warranty, even if FoneSafe have been advised of the possibility of such damages.",
      "This EULA is not intended to and does not (a) change or exclude any statutory consumer rights that cannot be lawfully changed or excluded, or (b) limit or exclude any right that you may have against any other party if you did not purchase the Software directly from FoneSafe website. Some jurisdictions do not allow certain liability exclusions, damage limitations, or disclaimers of warranty. In no event shall FoneSafe be liable for your use of the Software.",
    ],
  },
  {
    title: "Term and Termination",
    desc: [
      "The term of this License runs concurrently with the term of your Subscription plan, which is the period during which you are authorized to use the Software. Your rights under this License will terminate automatically without notice from FoneSafe if you fail to comply with any term(s) of this EULA or your Subscription period expires. Upon termination of the License, you shall cease all use of the Software and uninstall it from any devices on which it was installed.",
      "The terms of this EULA are subject to change at any time, and in the event of any changes, the updated version of the EULA will appear on this page.",
      "FoneSafe reserves the right to change this License granted to you at any time; the changes will be effective when posted on the website fonesafe.net. FoneSafe may also change, discontinue, or impose limits on certain features of the Software without liability. Your continued use of the Software indicates your consent to the changes.",
    ],
  },
];

const Eula = () => {
  return (
    <div style={styles.container}>
      <Header />
      <Banner text={title} />
      <div style={{ ...middleStyle, ...styles.content }}>
        {content.map((data, index) => (
          <Content {...data} key={index} />
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default Eula;
