import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import styles from "./index.styles";
import Banner from "./components/Banner";
import { middleStyle } from "../../constants";
import Content from "./components/Content";

const title = "Refund Policy";

const content = [
  {
    title: "",
    desc: [
		"Your purchase of FoneSafe Software shall be governed by this Refund Policy. This Refund Policy is an integral part of our Terms of Service, the provisions of which are as well applicable to your use and purchase of the Software.",
		"Please read this Refund Policy carefully before placing your order as it determines your rights with regard to your purchases, including essential restrictions and exclusions.",
		"Android devices should be rooted in order to use the advanced features of FoneSafe and be in conformity with the Compatibility Policy.",
    ],
  },
  {
    title: "General",
    desc: [
		"In case you have technical issues with the Software, which cannot be fixed by FoneSafe Customer Support Center, you may be eligible for a full refund in accordance with Refund Policy outlined below. However, we are convinced that most of claims for refund may be avoided if you accept professional help from our Customer Support Center. To contact the Customer Support Center please write to support@fonesafe.net.",
    ],
  },
  {
    title: "Refund Conditions",
    desc: [
		"Subject to the applicable law and to this Refund Policy conditions, you may be eligible to receive a full refund within 14 days following the day of your purchase as long as refund reasons do not contradict the Refund Policy conditions outlined below.",
		"No refund will be issued after 14 days have elapsed since the purchase date;",
		"The claim for refund may apply only to the primary (first) FoneSafe subscription;",
		"If you purchased additional subscription/s in another order, no refund will be issued;",
		"Your refund may be issued only once. If you buy another FoneSafe subscription at a later time, it will not be subject to the same refund request;",
		"No refund will be issued for Subscription Prolongation and for Upgrades or Downgrades to or from FoneSafe Subscription plans;",
		"Partial refund may be approved by the Billing Team upon the Customer`s consent;",
		"Processing fee at the amount of 9% from the original purchase amount may be deducted on a case-by-case basis.",
		"No refund will be issued in case:",
		"the user has lost its private encryption key, which has resulted in loss of data, temporary loss of access to the user's User Space or any other inconveniences for the user;",
		"the user refuses to re-install or re-link FoneSafe Software in the event of the performed upgrade of the operating system on the target device;",
		"the user’s target device is not in compliance with the FoneSafe Compatibility Policy;",
		"the target device has lost connection with FoneSafe subscription due to the absence of Internet access, factory reset or update to the latest operating system version;",
		"the user’s account was defined as Dormant. Account is regarded as Dormant if from the last log-in date passed 1.5 months (45 days) and the customer did not access his account within this time frame.",
		"In addition to the above, no refund can be issued to a user in case the reasons for a refund are completely beyond FoneSafe control. They include, but are not limited to:",
		"a target phone is not owned by a user or a user did not receive a consent of a target phone owner to install the FoneSafe Software;",
		"a target phone does not have Internet access (no money left on the target phone account, temporary service interruption, roaming-related problem, etc.);",
		"the carrier operator is changed by a target phone user that leads to the loss of Internet connectivity and consequently improper functioning of FoneSafe Software;",
		"update of operating system of a target phone;",
		"a target phone was reset to original factory settings;",
		"a user does not follow the installation guidelines of our customer support team via email or telephone call;",
		"a user does not accept technical assistance;",
		"a user does not have a physical access to a target phone or has forgotten the password to unlock it;",
		"a user did not receive the data that had been saved on a target phone before FoneSafe was installed on it;",
		"a user is either unable or does not want to obtain physical access to the target device in order to enable iCloud backup;",
		"2-factor/2-step verification is enabled on a target device and a user is unable to have physical access to the device.",
		"a user does not have up-to-date iCloud credentials for target Apple ID account;",
		"a target device runs unsupported operating system. The following operating systems are NOT supported: Symbian Belle, Windows Phone, Windows Mobile, BlackBerry 10 or Bada;",
		"a user intended to use FoneSafe Phone on more than one target device after purchasing one subscription plan;",
		"personal reasons (I’ve changed my mind, I’ve made a purchase by mistake, Software was not used, etc.);",
		"FoneSafe Software was unlinked or damaged by anti-virus software, target device’s owner or other services.",
    ],
  },
  {
    title: "Refund Procedure",
    desc: [
		"Please kindly follow the following steps to apply for Refund:",
		"Contact our Customer Service team here: support@fonesafe.net;",
		'Indicate the subject of your email as "Refund";',
		"Provide our Customer Service team your order details (email the account is registered; payment method connected to your account; transaction date and amount) and reason for returns;",
		"Precise reason of return is obligatory to you enquiry be considered.",
    ],
  },
];

const RefundPolicy = () => {
  return (
    <div style={styles.container}>
      <Header />
      <Banner text={title} />
      <div style={{ ...middleStyle, ...styles.content }}>
        {content.map((data, index) => (
          <Content {...data} key={index} />
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default RefundPolicy;