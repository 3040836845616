import { convert } from '../../../../constants';

const styles = {
	container: {
		paddingTop: convert(96),
	},
	left: {
		width: convert(540),
		marginRight: convert(100),
	},
	right: {
		flex: 1,
	},
	title: {
		fontSize: convert(40),
		fontWeight: '800',
		lineHeight: convert(56),
	},
	button: {
		marginTop: convert(18),
	},
	desc: {
		fontWeight: '400',
		fontSize: convert(16),
		lineHeight: convert(24),
		marginTop: convert(12),
		fontStyle: 'italic',
		marginBottom: convert(34),
	},
	featureIcon: {
		width: '80px',
		marginBottom: '-10px',
		marginLeft: '-17px',
	},
	featureItem: {
		display: 'flex',
		height: '28px',
		alignItems: 'center',
		width: '50%',
		marginBottom: '16px',
	},
	selected: { height: '28px' },
	featureText: {
		marginLeft: 4,
		color: '#202124',
		fontSize: '16px',
		whiteSpace: 'nowrap' as 'nowrap',
	},
	featureContainer: {
		display: 'flex',
		flexWrap: 'wrap' as 'wrap',
	},
};

export default styles;
