import { convert } from '../../../../constants';

const styles = {
	container: {
		background: '#202124',
	},
	wrapper: {
		paddingTop: convert(120),
		// justifyContent: 'space-between',
	},
	left: {
		color: '#fff',
		flex: 1,
	},
	title: {
		fontSize: convert(48),
		fontWeight: '800',
		lineHeight: convert(64),
		marginBottom: convert(26),
	},
	button: {
		marginTop: convert(40),
	},
	desc: {
		fontWeight: '400',
		fontSize: convert(18),
		lineHeight: convert(28),
		marginTop: convert(24),
		marginBottom: convert(24),
	},
	bannerImg: {
		width: convert(580),
		borderRadius: '10px',
		marginRight: convert(140),
	},
	featureIcon: {
		width: '80px',
		marginBottom: '-10px',
		marginLeft: '-17px',
	},
	featureItem: {
		display: 'flex',
		height: convert(28),
		alignItems: 'center',
		marginBottom: convert(16),
	},
	selected: { height: convert(28) },
	featureText: {
		marginLeft: 4,
		color: '#fff',
		fontSize: '16px',
		whiteSpace: 'nowrap' as 'nowrap',
	},
	featureContainer: {
		display: 'flex',
		flexDirection: 'column' as 'column',
	},
};

export default styles;
