const styles = {
	title: {
		fontSize: '36px',
		lineHeight: 1.5,
		marginTop: '24px',
	},
	p: {
		fontSize: '16px',
		lineHeight: '28px',
		color: '#666',
		marginTop: '24px',
	},
};

export default styles;
