import { convert } from '../../../../constants';

const styles = {
	container: {
		height: convert(616),
		position: 'relative' as 'relative',
		overflow: 'hidden',
	},
	left: {
		width: '50%',
		color: '#fff',
		paddingTop: convert(88),
	},
	title: {
		fontSize: convert(48),
		fontWeight: '800',
		lineHeight: convert(64),
	},
	button: {
		marginTop: convert(40),
	},
	desc: {
		fontWeight: '400',
		fontSize: '18px',
		lineHeight: '28px',
		marginTop: '24px',
	},
	bannerImg: {
		position: 'absolute' as 'absolute',
		right: 0,
		bottom: 0,
		width: convert(1000),
	},
};

export default styles;
