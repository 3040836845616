import { convert } from '../../constants';

const styles = {
	featureIcon: {
		width: '80px',
		marginBottom: '-10px',
		marginLeft: '-17px',
	},
	featureItem: {
		display: 'flex',
		height: convert(28),
		alignItems: 'center',
		marginBottom: convert(16),
	},
	selected: { height: convert(28) },
	featureText: {
		marginLeft: 4,
		color: '#202124',
		fontSize: convert(16),
		whiteSpace: 'nowrap' as 'nowrap',
	},
	featureContainer: {
		display: 'flex',
		flexDirection: 'column' as 'column',
	},
};

export default styles;
