const styles = {
	container: {
		background: 'rgb(106, 81, 255)',
		height: '200px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	h1: {
		fontSize: '40px',
		color: '#fff',
		fontWeight: '800',
	},
};

export default styles;
