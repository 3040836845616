import React from 'react';
import { Link } from 'react-router-dom';
import { middleStyle } from '../../../../constants';
import styles from './index.styles';

const banner1 = require('../../../../assets/banner6.jpg');

const Banner = () => {
	return (
		<div
			style={{
				...styles.container,
			}}
		>
			<img style={styles.bannerImg} src={banner1} alt="" />

			<div style={styles.left}>
				<h2 style={styles.title}>Talk to Customer Support Anytime, 24/7</h2>
				<p style={styles.desc}>
					When you have a smartphone or an online security question, it is not
					always feasible to make calls. But our technical support team is
					always available and ready to help you.
				</p>

				<div>
					<Link to="/pricing">
						<button style={styles.button}>Check Plans Now</button>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default Banner;
