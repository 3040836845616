/** @format */

import {Link} from 'react-router-dom';
import {t} from '../../utils/i18n';
import './index.css';

const bottomLogo = require('../../assets/logo_bottom@2x.png');

const LinkComponent = ({text, url}: {text: string; url: string}) => {
  return (
    <li>
      <Link className='link' to={url}>
        {text}
      </Link>
    </li>
  );
};

const Footer = () => {
  const links = [
    {
      text: t('privacy.notice'),
      url: '/privacy-notice',
    },
    {
      text: t('eula'),
      url: '/eula',
    },
    {
      text: t('terms.service'),
      url: '/terms-of-service',
    },
    {
      text: t('cookie.notice'),
      url: '/cookie-notice',
    },
    {
      text: t('refund.policy'),
      url: '/refund-policy',
    },
    {
      text: t('recurring.policy'),
      url: '/recurring-policy',
    },
    {
      text: t('contact'),
      url: '/contact-us',
    },
  ];

  return (
    <div className='footer-container'>
      <div className='middle wrapper'>
        <div className='top'>
          <img src={bottomLogo} className='bottomLogo' alt='' />
          <div className='bottom bottom-mobile'>
            <p className='copyRight'>{t('copyright')}</p>
          </div>
          <ul className='links'>
            {links.map(link => (
              <LinkComponent key={link.text} {...link} />
            ))}
          </ul>
        </div>
        <div className='bottom'>
          <p className='copyRight'>{t('copyright')}</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
