import React from 'react';
import { Link } from 'react-router-dom';
import { middleStyle } from '../../../../constants';
import styles from './index.styles';

const bannerBg = require('../../../../assets/banner_bg.jpg');

const Banner = () => {
	return (
		<div
			style={{
				...styles.container,
				background: `url(${bannerBg}) 0 0 no-repeat`,
				backgroundSize: '100% 100%',
			}}
		>
			<div style={middleStyle}>
				<div style={styles.left}>
					<h1 style={styles.title}>Secure Your Android Effortlessly</h1>
					<p style={styles.desc}>
						FoneSafe detects spyware and prevents risky wi-fi networks before
						you can notice a problem.
					</p>
					<Link to="/pricing">
						<button style={styles.button}>Download</button>
					</Link>

					<p style={styles.desc}>Compatible with all Android versions</p>
				</div>
			</div>
			<img
				style={styles.bannerImg}
				src={require('../../../../assets/banner_logo.png')}
				alt=""
			/>
		</div>
	);
};

export default Banner;
