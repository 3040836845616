/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import styles from './index.styles';

const selected = require('../../assets/selected@2x.png');

const FeatureItem = ({
	text,
	isActive,
}: {
	text: string;
	isActive?: boolean;
}) => {
	return (
		<div style={styles.featureItem}>
			<img style={styles.selected} src={selected} alt="" />
			<span
				style={{
					...styles.featureText,
					...(isActive && styles.featureTextActive),
				}}
			>
				{text}
			</span>
		</div>
	);
};

const Item = ({
	version,
	duration,
	price,
	features,
	isActive,
}: {
	version: string;
	duration: string;
	price: string;
	features: string[];
	isActive: boolean;
}) => {
	return (
		<div
			style={{
				...styles.item,
				...(isActive && styles.itemActive),
			}}
		>
			<p
				style={{ ...styles.itemTitle, ...(isActive && styles.itemTitleActive) }}
			>
				{version}
			</p>
			<div style={styles.itemContent}>
				<p style={styles.itemDuration}>{duration}</p>
				<p style={styles.itemPrice}>{price}</p>
				<div>
					{features.map((feature) => (
						<FeatureItem
							text={feature}
							key={feature}
							isActive={isActive}
						></FeatureItem>
					))}
				</div>
				<div style={styles.button}>
					<a
						href="#"
						className={`main-button ${isActive ? 'main-button-white' : ''}`}
					>
						Get plan
					</a>
				</div>
			</div>
		</div>
	);
};

const items = [
	{
		version: 'Basic',
		duration: '1 month',
		price: '$ 39.99',
		features: ['Security essentials in one app'],
		isActive: false,
	},
	{
		version: 'Premium',
		duration: '3 month',
		price: '$ 69.99',
		features: ['Security essentials in one app', 'Cross-platfom security help'],
		isActive: true,
	},
	{
		version: 'Family',
		duration: '12 month',
		price: '$ 169.99',
		features: [
			'Security essentials in one app',
			'Cross-platfom security help',
			'24/7 technical assistance',
		],
		isActive: false,
	},
];

const Pricing = () => {
	return (
		<>
			<Header></Header>
			<div style={styles.container}>
				<p style={styles.pricing}>PRICING</p>
				<p style={styles.title}>
					Choose your plan & start using FoneSafe in a minute!
				</p>
				<div style={styles.itemContainer}>
					{items.map((item) => (
						<Item {...item} key={item.version}></Item>
					))}
				</div>
			</div>
			<Footer></Footer>
		</>
	);
};

export default Pricing;
