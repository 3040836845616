const styles = {
	container: {},
	content: {
		flexDirection: 'column' as 'column',
		paddingTop: '20px',
		paddingBottom: '50px',
	},
};

export default styles;
