import React from 'react';
import styles from './index.styles';

const Button = ({ onPress, text }: { onPress?: () => void; text: string }) => {
	return (
		<button onClick={onPress} title={text}>
			{text}
		</button>
	);
};

export default Button;
