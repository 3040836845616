import { convert } from '../../../../constants';

const styles = {
	container: {
		padding: `${convert(96)} 0`,
	},
	wrapper: {
		// justifyContent: 'space-between',
	},
	right: {
		flex: 1,
	},
	text: {
		fontSize: convert(16),
		lineHeight: convert(24),
		marginBottom: convert(12),
	},
	left: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column' as 'column',
		alignItems: 'flex-start',
		width: convert(520),
		marginRight: convert(40),
	},
	title: {
		fontWeight: '800',
		fontSize: convert(40),
		lineHeight: convert(56),
		marginBottom: convert(52),
	},
	desc: {
		fontWeight: '400',
		fontSize: convert(18),
		lineHeight: convert(28),
		marginTop: convert(24),
		marginBottom: convert(24),
	},
	button: {
		// background: '#fff',
		// color: '#6A51FF',
	},
};

export default styles;
