import React from 'react';
import styles from './index.styles';

const selected = require('../../assets/selected@2x.png');

const FeatureItem = ({
	text,
	style,
	allowWrap,
}: {
	text: string;
	style?: any;
	allowWrap?: boolean;
}) => {
	return (
		<div style={{ ...styles.featureItem, ...style }}>
			<img style={styles.selected} src={selected} alt="" />
			<span
				style={{
					...styles.featureText,
					...(allowWrap && { whiteSpace: 'normal' as 'normal' }),
				}}
			>
				{text}
			</span>
		</div>
	);
};

export default FeatureItem;
