import React from 'react';
import { Link } from 'react-router-dom';
import { middleStyle } from '../../../../constants';
import styles from './index.styles';

const banner1 = require('../../../../assets/banner3.png');
const selected = require('../../../../assets/selected@2x.png');
const featureIcon = require('../../../../assets/feature3@2x.png');

const FeatureItem = ({ text }: { text: string }) => {
	return (
		<div style={styles.featureItem}>
			<img style={styles.selected} src={selected} alt="" />
			<span style={styles.featureText}>{text}</span>
		</div>
	);
};

const features = [
	'A team of qualified support professionals available 24/7',
	'Support can help with any and all Android issues',
	'Support available on call and messenger',
];

const Banner = () => {
	return (
		<div
			style={{
				...styles.container,
			}}
		>
			<div style={{ ...middleStyle, ...styles.wrapper }}>
				<div style={styles.left}>
					<img style={styles.featureIcon} src={featureIcon} alt="" />

					<h1 style={styles.title}>Advanced Cybersecurity Support 24/7</h1>

					<div style={styles.featureContainer}>
						{features.map((feature) => (
							<FeatureItem text={feature} key={feature} />
						))}
					</div>
					<Link to="/pricing">
						<button style={styles.button}>Try Now</button>
					</Link>
				</div>

				<img style={styles.bannerImg} src={banner1} alt="" />
			</div>
		</div>
	);
};

export default Banner;
