import { ReactNode } from 'react';
import styles from './index.styles';

const Content = ({ title, desc }: { title: string; desc: ReactNode[] }) => {
	return (
		<div>
			{title && <h2 style={styles.title}>{title}</h2>}
			{desc.map((data, index) => (
				<p style={styles.p} key={index}>
					{data}
				</p>
			))}
		</div>
	);
};

export default Content;
