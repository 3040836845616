import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Banner from './components/Banner';
import Banner1 from './components/Banner1';
import Banner2 from './components/Banner2';
import Banner3 from './components/Banner3';
import Banner4 from './components/Banner4';
import Banner5 from './components/Banner5';
import Feature from './components/Feature';
import styles from './index.styles';
import Swiper from './components/Swiper';

const Home = () => {
	return (
		<div style={styles.container}>
			<Header />
			<Banner />
			<Feature />
			<Banner1 />
			<Banner2 />
			<Banner3 />
			<Banner4 />
			<Swiper />
			<Banner5 />
			<Footer />
		</div>
	);
};

export default Home;
