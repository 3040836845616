import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import styles from './index.styles';

const Item = ({ title, desc }: { title: string; desc: string | string[] }) => {
	const ps = typeof desc === 'string' ? [desc] : desc;
	return (
		<div style={styles.itemContainer}>
			<h2 style={styles.h2}>{title}</h2>
			{ps.map((p, index) => (
				<p style={styles.desc} key={index}>
					{p}
				</p>
			))}
		</div>
	);
};

const items = [
	{
		title: 'How Do I Install FoneSafe?',
		desc: 'The package you want to buy should be the first thing you decide. After completing the purchase, you will get an email with the login information and password for your FoneSafe account, where you may download the software and an installation manual.',
	},
	{
		title: 'Can I Get A Customized Plan?',
		desc: "Absolutely! We provide customized packages for customers who require assistance with specific settings or additional services beyond the typical smartphone or computer user's activity.",
	},
	{
		title: 'What Services Will I Get From Your Technical Support Team?',
		desc: "Our expert technical team will help you with any security issues when using your smartphone, laptop, and internet resources. If you are unsure as to why you are getting this or that pop-up message, your computer is running slowly, or your phone's battery is draining faster than usual, there is no need to call your relatives or friends. That is what our technical assistants are for!",
	},
	{
		title: 'How Exactly Does FoneSafe Work?',
		desc: [
			'The FoneSafe application monitors all processes on your Android smartphone, identifies the risky ones, and provides security recommendations. Also, FoneSafe examines your mobile device for viruses and spyware, compares the results to our database of malicious software, and immediately bans such applications.',
			'If you purchase the Plus package, you will receive first assistance with installation and first scan security advice from a specialist. By you choose the Advanced package, you gain access to a security expert who is available around the clock to answer your questions about Smartphones, computers, and online safety.',
		],
	},
	{
		title: 'Where Can I Contact Technical Support?',
		desc: 'Technical support is provided through phone or messenger for customers who purchase Plus and Advanced packages.',
	},
	{
		title: 'More Questions?',
		desc: [
			'Please email us if you have any comments regarding our product or the performance of our team members.',
			'If you are not our customer but would like to contact us about a business-related matter, we would be glad to discuss it with you.',
			'We are open to the following types of partnerships: public relations, affiliate marketing, and cross-sales.',
		],
	},
];

const FAQ = () => {
	return (
		<div style={styles.container}>
			<Header />
			<div style={styles.faq}>
				<h1 style={styles.title}>FAQ</h1>
				{items.map((item) => (
					<Item {...item} key={item.title} />
				))}
			</div>
			<Footer />
		</div>
	);
};

export default FAQ;
