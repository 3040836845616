import styles from './index.styles';

const Banner = ({ text }: { text: string }) => {
	return (
		<div style={styles.container}>
			<h1 style={styles.h1}>{text}</h1>
		</div>
	);
};

export default Banner;
