const width = window.innerWidth;
const ratio = Math.min(1.0, width / 1920);

export const convert = (value: number) => {
	return Math.floor(value * ratio) + 'px';
};

export const middleStyle = {
	width: convert(1300),
	minWidth: '1120px',
	margin: '0 auto',
	display: 'flex',
};

// export const goPricing = () => {
// 	// window.location.href = '/pricing';
// 	window.history.pushState({}, '', '/pricing');
// };
