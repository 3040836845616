import React from 'react';
import { Link } from 'react-router-dom';
import { middleStyle } from '../../../../constants';
import styles from './index.styles';

const featureIcon = require('../../../../assets/feature_icon@2x.png');
const featureLogo = require('../../../../assets/feature_logo@2x.png');
const selected = require('../../../../assets/selected@2x.png');

const FeatureItem = ({ text }: { text: string }) => {
	return (
		<div style={styles.featureItem}>
			<img style={styles.selected} src={selected} alt="" />
			<span style={styles.featureText}>{text}</span>
		</div>
	);
};

const features = [
	'App Activity',
	'Advanced Apps Blocking',
	'Suspicious WiFi Protection',
	'Advanced Location Tracking',
	'Spyware Protection',
	'Browser Security Protection',
];

const Feature = () => {
	return (
		<div style={styles.container}>
			<div style={middleStyle}>
				<img style={styles.left} src={featureLogo} alt="" />
				<div style={styles.right}>
					<img style={styles.featureIcon} src={featureIcon} alt="" />
					<h2 style={styles.title}>Essential Android Security Features</h2>
					<p style={styles.desc}>Automatic and fully supported</p>
					<div style={styles.featureContainer}>
						{features.map((feature) => (
							<FeatureItem text={feature} key={feature} />
						))}
					</div>

					<Link to="/pricing">
						<button style={styles.button}>Download</button>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default Feature;
