import { convert } from '../../constants';

const styles = {
	container: {},
	faq: {
		width: '900px',
		margin: '40px auto',
		background: '#FAFAFA',
		borderRadius: '4px',
		padding: '40px 50px',
	},
	title: {
		fontWeight: '800',
		fontSize: '24px',
		lineHeight: '36px',
		color: '#202124',
		marginBottom: '20px',
	},
	itemContainer: {
		padding: '24px',
		background: '#fff',
		borderRadius: '4px',
	},
	h2: {
		fontWeight: '700',
		fontSize: '16px',
		lineHeight: '24px',
	},
	desc: {
		fontSize: '14px',
		lineHeight: '22px',
		marginTop: '8px',
	},
};

export default styles;
