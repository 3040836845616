import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import styles from "./index.styles";
import Banner from "./components/Banner";
import { middleStyle } from "../../constants";
import Content from "./components/Content";

const title = "Cookie Notice";

const content = [
  {
    title: "",
    desc: [
		"We use cookies on our Website located at support@fonesafe.net. This Cookie Notice is here to help you understand how we use cookies and how you can control the use of cookies. If you have any further questions, please send us an email to support@fonesafe.net, you can also send us a letter to our address listed above.",
    ],
  },
  {
    title: "What is a cookie?",
    desc: [
		"Cookies are a small text file that are stored on your device. Cookies are widely used to make online services work or to be better and more efficient. They can do this because online services can read and write these files, enabling them to recognize you and remember important information that will make your use of them more convenient (e.g. by remembering your user preferences).",
    ],
  },
  {
    title: "What cookies do we use?",
    desc: [
		"Below we list the different types of cookies we may use.",
		"Universal Analytics (Google): These cookies are used to collect information about how our website is used. We use the information to improve the website. The cookies collect information in a way that does not directly identify anyone. They collect the number of visitors to the website, where visitors have come from and the pages they visited.",
		"Microsoft Advertising: Used by the Microsoft advertising system to track ad performance. This cookie contains an anonymous user id.",
		"CloudFlare: Cookie used to speed up page load times. Also it is used to override any security restrictions based on the IP address the visitor is coming from. It does not contain any user identification information.",
		"Facebook: Used by the FAcebook advertising system to track ad performance. This cookie contains an Encrypted Facebook ID and Browser ID.",
    ],
  },
  {
    title: "Changes to this Cookie Notice",
    desc: [
		"We can update this Cookie Notice if necessary. Any changes will come into effect 30 days later. Please, check out “last updated” date at the top of this page to see when this Cookie Notice was last revised.",
    ],
  },
  {
    title: "More about privacy",
    desc: [
		"Please also read our Privacy Notice, which gives more information about how we use your personal data. Link https://fonesafe.net/privacy-notice",
    ],
  },
];

const CookieNotice = () => {
  return (
    <div style={styles.container}>
      <Header />
      <Banner text={title} />
      <div style={{ ...middleStyle, ...styles.content }}>
        {content.map((data, index) => (
          <Content {...data} key={index} />
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default CookieNotice;