import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router';

declare var location;

const Middle = () => {
	const { id: cart } = useParams();
	// const navigate = useNavigate();

	const ref = useRef(false);
	useEffect(() => {
		if (ref.current === false) {
			ref.current = true;
			// navigate(`/payment/${cart}`);
			location.href = location.href.replace('cart', 'payment');
		}
	}, [cart]);
	return <></>;
};

export default Middle;
