const styles = {
	container: {
		textAlign: 'center' as 'center',
		paddingTop: '48px',
		paddingBottom: '92px',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column' as 'column',
	},
	pricing: {
		fontWeight: '700',
		fontSize: '18px',
		lineHeight: '28px',
		color: '#8C79FF',
	},
	title: {
		marginTop: '16px',
		fontWeight: '800',
		fontSize: '36px',
		lineHeight: '48px',
		marginBottom: '48px',
		width: '570px',
	},
	featureItem: {
		display: 'flex',
		height: '28px',
		alignItems: 'center',
		marginBottom: '16px',
	},
	selected: { height: '28px' },
	featureText: {
		marginLeft: 4,
		fontSize: '16px',
		whiteSpace: 'nowrap' as 'nowrap',
	},
	featureTextActive: {
		color: '#fff',
	},
	featureContainer: {
		display: 'flex',
		flexDirection: 'column' as 'column',
	},
	itemContainer: {
		width: '1180px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-end',
		height: '544px',
	},
	item: {
		width: '380px',
		height: '472px',
		border: '1px solid #E8EAF2',
		display: 'flex',
		flexDirection: 'column' as 'column',
		boxShadow: '0px 2px 10px rgba(0, 80, 125, 0.08)',
		borderRadius: '20px',
	},
	itemActive: {
		height: '100%',
		background: '#8C79FF',
		color: '#fff',
	},
	itemTitle: {
		height: '60px',
		lineHeight: '60px',
		background: '#FAFAFA',
		borderRadius: '20px 20px 0px 0px',
		fontWeight: '700',
		fontSize: '18px',
		color: '#555555',
	},
	itemTitleActive: {
		background: '#6A51FF',
		color: '#fff',
	},
	itemContent: {
		padding: '20px 40px',
		display: 'flex',
		flexDirection: 'column' as 'column',
		flex: 1,
	},
	button: {
		flex: 1,
		display: 'flex',
		alignItems: 'center',
	},
	itemDuration: {
		fontWeight: '800',
		fontSize: '28px',
		lineHeight: '40px',
		marginBottom: '20px',
	},
	itemPrice: {
		fontWeight: '800',
		fontSize: '48px',
		lineHeight: '64px',
		marginBottom: '20px',
	},
};

export default styles;
