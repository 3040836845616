import React, { useEffect, useRef, useState } from 'react';
import styles from './index.styles';
import SwiperCore, { EffectCoverflow, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Thumbs, A11y } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const left = require('../../../../assets/left@2x.png');
const right = require('../../../../assets/right@2x.png');

SwiperCore.use([EffectCoverflow, Pagination]);

const SwiperComponent = () => {
	const [swiperRef] = useState(null);

	return (
		<div style={styles.container}>
			<div className="top-swipper">
				<p style={styles.title}>
					Our Customers and Industry Experts Love FoneSafe
				</p>
			</div>

			<div style={styles.swiperContainer}>
				<div style={styles.swiperTop}>
					<div style={styles.swiperLeft} id="prev">
						<img src={left} style={styles.swiperLeftImg} alt="" />
					</div>
					<Swiper
						// install Swiper modules
						modules={[Thumbs, Pagination, Navigation, A11y]}
						thumbs={{ swiper: swiperRef }}
						// slidesPerView={1}
						navigation={{
							nextEl: '#next',
							prevEl: '#prev',
						}}
						pagination={{ clickable: true }}
						style={styles.swiperInner}
						onSwiper={(swiper) => console.log(swiper)}
						onSlideChange={() => console.log('slide change')}
						className="mySwipper"
					>
						<SwiperSlide>
							<div style={styles.swiperSlide} key="slide1">
								<p style={styles.text}>
									“I had problems dealing with viruses and malware. After I
									installed FoneSafe, my Andorid no longer stalls or runs the
									risk of being corrupted unexpectedly. I am glad I discovered
									FoneSafe when I did”
								</p>
								<p style={{ ...styles.text, ...styles.author }}>
									Stephen Polmans
								</p>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div style={styles.swiperSlide} key="slide1">
								<p style={styles.text}>
									“I had problems dealing with viruses and malware. After I
									installed FoneSafe, my Andorid no longer stalls or runs the
									risk of being corrupted unexpectedly. I am glad I discovered
									FoneSafe when I did”
								</p>
								<p style={{ ...styles.text, ...styles.author }}>
									Stephen Polmans
								</p>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div style={styles.swiperSlide} key="slide1">
								<p style={styles.text}>
									“I had problems dealing with viruses and malware. After I
									installed FoneSafe, my Andorid no longer stalls or runs the
									risk of being corrupted unexpectedly. I am glad I discovered
									FoneSafe when I did”
								</p>
								<p style={{ ...styles.text, ...styles.author }}>
									Stephen Polmans
								</p>
							</div>
						</SwiperSlide>
					</Swiper>
					<div style={styles.swiperRight} id="next">
						<img src={right} style={styles.swiperRightImg} alt="" />
					</div>
				</div>
			</div>

			{/* <div style={styles.swiperContainer}>
				<div style={styles.swiperTop}>
					<div style={styles.swiperLeft} onClick={goPrev}>
						<img src={left} style={styles.swiperLeftImg} alt="" />
					</div>

					<div style={{ ...styles.swiperInner }}>
						<div
							className="swiper-container"
							style={{ ...styles.swiperInnerContainer }}
						>
							<div style={styles.swiperSlide} key="slide1">
								<p style={styles.text}>
									“I had problems dealing with viruses and malware. After I
									installed FoneSafe, my Andorid no longer stalls or runs the
									risk of being corrupted unexpectedly. I am glad I discovered
									FoneSafe when I did”
								</p>
								<p style={{ ...styles.text, ...styles.author }}>
									Stephen Polmans
								</p>
							</div>

							<div style={styles.swiperSlide} key="slide2">
								<p style={styles.text}>22222</p>
								<p style={{ ...styles.text, ...styles.author }}>33</p>
							</div>

							<div style={styles.swiperSlide} key="slide3">
								<p style={styles.text}>
									“I had problems dealing with viruses and malware. After I
									installed FoneSafe, my Andorid no longer stalls or runs the
									risk of being corrupted unexpectedly. I am glad I discovered
									FoneSafe when I did”
								</p>
								<p style={{ ...styles.text, ...styles.author }}>
									Stephen Polmans
								</p>
							</div>
						</div>
					</div>
					<div style={styles.swiperRight} onClick={goNext}>
						<img src={right} style={styles.swiperRightImg} alt="" />
					</div>
				</div>

				<div className="swiper-pagination"></div>
			</div> */}
		</div>
	);
};

export default SwiperComponent;
