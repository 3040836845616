/** @format */

import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../Button';
import './index.css';

import minspyLogo from '../../assets/minspy_logo.svg';
// import spyicLogo from '../../assets/spyic_logo.svg';
import neatspyLogo from '../../assets/neatspy_logo.svg';
import spyierLogo from '../../assets/spyier_logo.svg';
import spyzieLogo from '../../assets/spyzie_logo.svg';
import spyineLogo from '../../assets/spyine_logo.svg';
import clickfreeLogo from '../../assets/clickfree_logo.svg';
import safespyLogo from '../../assets/safespy_logo.svg';
import { t } from '../../utils/i18n';

const cocospyLogo = require('../../assets/cocospy.png');
const spyicLogo = require('../../assets/spyic_logo.png');

const Nav = ({
	type,
	url,
	text,
}: {
	type: string;
	url: string;
	text: string;
}) => {
	if (type === 'link') {
		return (
			<li className="navLink">
				<Link to={url}>
					<div className="linkText" title={text}>
						{text}
					</div>
				</Link>
			</li>
		);
	}

	return (
		<Link to={url}>
			<Button text={text} />
		</Link>
	);
};

const Header = ({ website,isNav = true }: { website?: string;isNav?: boolean; }) => {
	const [ifShowMenu, setIfShowMenu] = useState(false);

	const linkConfig = [
		{
			type: 'link',
			url: '/product',
			text: t('product'),
		},
		{
			type: 'link',
			url: '/pricing',
			text: t('pricing'),
		},
		{
			type: 'link',
			url: '/faq',
			text: t('faq'),
		},
		{
			type: 'button',
			url: '/pricing',
			text: t('download'),
		},
	];

	const image = useMemo(() => {
		switch (website) {
			case 'cocospy':
				return cocospyLogo;
			case 'spyic':
				return spyicLogo;
			case 'minspy':
				return minspyLogo;
			case 'spyier':
				return spyierLogo;
			case 'spyzie':
				return spyzieLogo;
			case 'spyine':
				return spyineLogo;
			case 'neatspy':
				return neatspyLogo;
			case 'safespy':
				return safespyLogo;
			case 'clickfree':
				return clickfreeLogo;
			default:
				return require('../../assets/top_logo@2x.png');
		}
	}, [website]);

	return (
		<>
			<div className="wrapper">
				<div className="middle container">
					<Link to="/">
						<div className="logo-wrapper">
							<img
								src={require('../../assets/top_logo@2x.png')}
								className="logo"
								alt=""
							/>
							{website && website !== 'fonesafe' ? (
								<>
									<div className="line"></div>
									<img src={image} className="website-logo" alt="" />
								</>
							) : null}
						</div>
					</Link>
					{
						isNav?
						<>
						<ul className="linkUl">
							{linkConfig.map((link, index) => (
								<Nav {...link} key={index} />
							))}
						</ul>

						<div className="menu" onClick={() => setIfShowMenu(true)}>
							<img
								className="menu-image"
								src={require('../../assets/menu@2x.png')}
								alt=""
							/>
						</div></>:null
					}
				</div>
			</div>
			{ifShowMenu ? (
				<div className="mask">
					<div className="mask-container">
						<div className="mask-top" onClick={() => setIfShowMenu(false)}>
							<img
								className="close"
								src={require('../../assets/close_gray.png')}
								alt=""
							/>
						</div>
						<ul className="linkUl linkUl-mobile">
							{linkConfig.map((link, index) => (
								<Nav {...link} key={index} />
							))}
						</ul>
					</div>
				</div>
			) : (
				false
			)}
		</>
	);
};

export default Header;
