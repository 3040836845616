import React from 'react';
import { Link } from 'react-router-dom';
import { middleStyle } from '../../../../constants';
import styles from './index.styles';

const banner1 = require('../../../../assets/banner1.png');
const selected = require('../../../../assets/selected@2x.png');
const featureIcon = require('../../../../assets/upgrade@2x.png');

const FeatureItem = ({ text }: { text: string }) => {
	return (
		<div style={styles.featureItem}>
			<img style={styles.selected} src={selected} alt="" />
			<span style={styles.featureText}>{text}</span>
		</div>
	);
};

const features = [
	'Easy and intuitive installation process',
	'Top-tier security features',
	'Reliable partner for your Android security',
];

const Banner = () => {
	return (
		<div
			style={{
				...styles.container,
			}}
		>
			<div style={{ ...middleStyle, ...styles.wrapper }}>
				<div style={styles.left}>
					<img style={styles.featureIcon} src={featureIcon} alt="" />

					<h1 style={styles.title}>Android Security Made Easy</h1>
					<p style={styles.desc}>
						Install and secure your mobile device in a few simple steps
					</p>
					<div style={styles.featureContainer}>
						{features.map((feature) => (
							<FeatureItem text={feature} key={feature} />
						))}
					</div>
					<Link to="/pricing">
						<button style={styles.button}>Download Now</button>
					</Link>
				</div>

				<img style={styles.bannerImg} src={banner1} alt="" />
			</div>
		</div>
	);
};

export default Banner;
