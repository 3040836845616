import React from 'react';
import FeatureItem from '../../../../components/Feature';
import { middleStyle } from '../../../../constants';
import styles from './index.styles';

const features = [
	'Block suspicious unauthorized applications',
	'Protect your safety while using Wifi',
	'Protect you from new and existing spyware',
	'Protect you from new and existing spyware',
];

const Summary = () => {
	return (
		<div style={{ ...middleStyle, ...styles.container }}>
			<div style={styles.inner}>
				<div style={styles.wrapper}>
					{features.map((feature, index) => (
						<FeatureItem
							text={feature}
							key={index}
							allowWrap={true}
							style={styles.feature}
						></FeatureItem>
					))}
				</div>
			</div>
		</div>
	);
};

export default Summary;
