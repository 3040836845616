import { convert } from '../../../../constants';

const styles = {
	item: {
		position: 'relative' as 'relative',
		width: convert(412),
		height: convert(268),
		padding: convert(32),
		background: '#F6F7F8',
		borderRadius: '20px',
		marginRight: convert(32),
		marginBottom: convert(32),
		boxSizing: 'border-box' as 'border-box',
	},
	noRightMargin: {
		marginRight: 0,
	},
	feature: {
		alignItems: 'flex-start',
		height: 'auto',
		marginBottom: convert(8),
	},
	h2: {
		width: convert(860),
		textAlign: 'center' as 'center',
		fontWeight: '800',
		fontSize: convert(40),
		lineHeight: convert(56),
	},
	itemTitle: {
		fontWeight: '800',
		fontSize: convert(24),
		lineHeight: convert(36),
		color: '#6A51FF',
		marginBottom: convert(20),
	},
	container: {
		marginTop: convert(176),
		display: 'flex',
		flexDirection: 'column' as 'column',
		alignItems: 'center',
	},
	title: {
		width: convert(860),
	},
	wrapper: {
		flexWrap: 'wrap' as 'wrap',
		marginTop: convert(48),
	},
};

export default styles;
