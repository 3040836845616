import { convert } from '../../../../constants';

const styles = {
	item: {
		position: 'relative' as 'relative',
		width: convert(384),
		height: convert(200),
		padding: convert(32),
		background: '#F6F7F8',
		borderRadius: '20px',
		marginRight: convert(24),
		boxSizing: 'border-box' as 'border-box',
	},
	noMarginRight: {
		marginRight: 0,
	},
	feature: {
		alignItems: 'flex-start',
		height: 'auto',
		marginBottom: convert(8),
	},
	h2: {
		width: convert(860),
		textAlign: 'center' as 'center',
		fontWeight: '800',
		fontSize: convert(40),
		lineHeight: convert(56),
		color: '#fff',
	},
	itemTitle: {
		fontWeight: '800',
		fontSize: convert(24),
		lineHeight: convert(36),
		color: '#6A51FF',
		marginBottom: convert(12),
	},
	itemDesc: {
		fontSize: convert(14),
		lineHeight: convert(22),
	},
	container: {
		display: 'flex',
		flexDirection: 'column' as 'column',
		alignItems: 'center',
		background: '#6A51FF',
		padding: `${convert(96)} 0`,
	},
	title: {
		width: convert(860),
	},
	wrapper: {
		marginTop: convert(48),
		justifyContent: 'center',
	},
};

export default styles;
