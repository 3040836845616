/** @format */

import React, { useEffect, useMemo, useRef, useState } from 'react';
import './index.css';
import { useParams } from 'react-router-dom';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import axios from 'axios';

import cocospy from '../../assets/cocospy.svg';
import minspy from '../../assets/minspy.svg';
import spyic from '../../assets/spyic.svg';
import neatspy from '../../assets/neatspy.svg';
import spyier from '../../assets/spyier.svg';
import spyzie from '../../assets/spyzie.svg';
import spyine from '../../assets/spyine.svg';
import clickfree from '../../assets/clickfree.svg';
import { LanguageMapKeys, t } from '../../utils/i18n';

const Item = ({ label, value }: { label: string; value: string }) => {
	return (
		<li className="item">
			<p className="label">{label}</p>
			<p className="value">{value}</p>
		</li>
	);
};

declare var Paddle;
declare var location;

const localeMap = {
	[LanguageMapKeys.en]: 'en',
	[LanguageMapKeys.de]: 'de',
	[LanguageMapKeys.ja]: 'ja',
	[LanguageMapKeys.it]: 'it',
	[LanguageMapKeys.ru]: 'ru',
	[LanguageMapKeys.fr]: 'fr',
	[LanguageMapKeys.es]: 'es',
	[LanguageMapKeys['pt-br']]: 'pt',
};

// 获取url参数

const Payment = () => {
	const [product, setProduct] = useState<any>({
		name: 'test',
		month: 3,
		price: 100,
	});
	// const [product, setProduct] = useState(null);
	const { id: cart } = useParams();
	const [origin, setOrigin] = useState('fonesafe');

	const items = useMemo(
		() =>
			product
				? [
						{
							label: t('plan'),
							value: product.name,
						},
						{
							label: t('subscription.period'),
							value: t('month', {
								count: product.month,
							}),
						},
						{
							label: t('month.price', {
								count: 1,
							}),
							value: `$${
								Math.floor((product.price / product.month) * 100) / 100
							}`,
						},
				  ]
				: [],
		[product]
	);

	const getCardInfo = async () => {
		// 获取
		try {
			const result = await axios.post('/api/cartCheckout/getCartInfo', {
				data: {
					cart,
				},
			});
			setProduct(result.data.data.products[0]);
			const { pay_method, website } = result.data.data;
			setOrigin(website);
			const method = pay_method.filter((data) => data.type === 'paddle')[0];
			if (!method) {
				return;
			}
			// 渲染paddle
			if (method.is_test) {
				Paddle.Environment.set('sandbox');
			}
			Paddle.Setup({
				vendor: method.client_id,
				eventCallback: (data) => {
					if (data.event === 'Checkout.Complete') {
						console.log(data.eventData); // Data specifics on the event
						if (website) {
							const path =
								(window as any).lang === 'en' ? '' : `/${(window as any).lang}`;
							let domainLtd = 'com';
							if (website === 'spyzie') {
								domainLtd = 'io';
							}
							if (website === 'fonemonitor') {
								domainLtd = 'co';
							}
							if (website === 'teensafe') {
								domainLtd = 'net';
							}

							location.href =
								`https://${website}.${domainLtd}${path}/payment.html?id=` +
								cart;
						} else {
							location.href = '/';
						}
					} else if (data.event === 'Checkout.Close') {
						console.log(data.eventData); // Data specifics on the event
					}
				},
			});
			// 开启paddle
			Paddle.Checkout.open({
				method: 'inline',
				email: result.data.data.email,
				country: result.data.data.country,
				locale: localeMap[(window as any).lang],
				product: result.data.data.products[0].paddle_id, // Replace with your Product or Plan ID
				// product: method.is_test ? 1 : result.data.data.products[0].id, // Replace with your Product or Plan ID
				allowQuantity: false,
				disableLogout: true,
				passthrough: cart,
				frameTarget: 'checkout-container', // The className of your checkout <div>
				// frameInitialHeight: 416,
				frameStyle:
					'width:100%; min-width:312px; background-color: transparent; border: none;', // Please ensure the minimum width is kept at or above 286px with checkout padding disabled, or 312px with checkout padding enabled. See "General" section under "Branded Inline Checkout" below for more information on checkout padding.
			});
		} catch (e) {
			alert(e.message);
		}
	};

	const ref = useRef(false);

	useEffect(() => {
		// 根据
		if (ref.current === false) {
			getCardInfo();
			ref.current = true;
		}
	}, []);

	const image = useMemo(() => {
		console.log(origin);
		switch (origin) {
			case 'cocospy':
				return cocospy;
			case 'spyic':
				return spyic;
			case 'minspy':
				return minspy;
			case 'spyier':
				return spyier;
			case 'spyzie':
				return spyzie;
			case 'spyine':
				return spyine;
			case 'neatspy':
				return neatspy;
			case 'safespy':
				return require('../../assets/safespy.png');
			case 'clickfree':
				return clickfree;
			default:
				return require('../../assets/box@2x.png');
		}
	}, [origin]);

	return (
		<div>
			<Header website={origin} isNav={false}></Header>
			<div className="content">
				{/* {product ? ( */}
				<div className="left">
					<div className="fonesafe">
						<img src={image} alt="" />
						<div className="info">
							<h2>{t('total.price')}</h2>
							<p className="price">${product.price}</p>
						</div>
					</div>
					<ul className="list">
						{items.map((item) => (
							<Item {...item} key={item.label} />
						))}
					</ul>
					{/* <p className="coupon">I have a coupon code</p> */}
					<Item label={t('total.price')} value={`$${product.price}`}></Item>
					<div className="renewal">
					Reminder: Visa is not supported. Please use other types of credit cards or PayPal.
					{/* {t('automatic.renewal')}: <span className="toggle">{t('on')}</span> */}
					</div>
					<div className="notice">
						<p className="notice-tip">{t('notice.tip')}</p>
						<div className="icon-list">
							<img src={require('../../assets/tip1@2x.png')} alt="" />
							<img src={require('../../assets/tip2@2x.png')} alt="" />
							<img src={require('../../assets/tip3@2x.png')} alt="" />
							<img src={require('../../assets/tip4@2x.png')} alt="" />
							<img src={require('../../assets/tip5@2x.png')} alt="" />
						</div>
					</div>
				</div>
				{/* ) : null} */}

				<div className="right checkout-container"></div>
			</div>
			<Footer></Footer>
		</div>
	);
};

export default Payment;
