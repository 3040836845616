import React from 'react';
import FeatureItem from '../../../../components/Feature';
import { middleStyle } from '../../../../constants';
import styles from './index.styles';

const steps = [
	{
		title: 'Step 1',
		content:
			'Choose a plan. Visit the Pricing page and select one of the available packages. Proceed with the transaction and receive a confirmation email.',
	},
	{
		title: 'Step 2',
		content:
			'Download and install FoneSafe. Go to your FoneSafe account on the website and download and install FoneSafe to your Android.',
	},
	{
		title: 'Step 3',
		content:
			'Start receiving real-time results and tips. FoneSafe will automatically security scan your device and display results on your FoneSafe account.',
	},
];

const Item = ({
	title,
	content,
	index,
}: {
	title: string;
	content: string;
	index: number;
}) => {
	return (
		<div
			style={{ ...styles.item, ...(index % 3 === 2 && styles.noMarginRight) }}
		>
			<p style={styles.itemTitle}>{title}</p>
			<p style={styles.itemDesc}>{content}</p>
		</div>
	);
};

const Feature = () => {
	return (
		<div style={styles.container}>
			<h2 style={styles.h2}>
				FoneSafe: Effortless to Install and Use. No Technical Knowledge
				Necessary
			</h2>
			<div style={{ ...middleStyle, ...styles.wrapper }}>
				{steps.map((item, index) => (
					<Item {...item} index={index} key={item.title}></Item>
				))}
			</div>
		</div>
	);
};

export default Feature;
