import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import styles from "./index.styles";
import Banner from "./components/Banner";
import { middleStyle } from "../../constants";
import Content from "./components/Content";

const title = "Terms of Service";

const content = [
  {
    title: "",
    desc: [
		"Please read these Terms of Service (“Terms”) carefully prior to accessing or using our fonesafe.net website and FoneSafe Software application made available through or in connection with the website (hereinafter together referred to as the “Service”). These Terms contain important information about your legal rights and obligations.",
		"Note, by accessing or using the Service, you represent and warrant that you have read, understand, have the legal capacity to and hereby agree to be legally bound by and to comply with these Terms in full. If you do not agree with these Terms, please, do not continue to use the Service.",
		"Our collection and use of personal data in connection with your access to and use of the Service is described in our Privacy Notice.",
		"If you have any questions or comments about these Terms of Service or the Service, please contact us at support@fonesafe.net.",
    ],
  },
  {
    title: "Entire agreement",
    desc: [
		"These Terms and any policies, including our End User License Agreement, Privacy Policy, Refund Policy, Cookie Policy and Compatibility Policy and any operating rules posted through the Service constitute the entire legally binding agreement and understanding between you and FoneSafe, governing your access to and use of the Service (“Agreement”). Our Privacy Notice and other policies applicable to your use of the Service are incorporated by reference into this Agreement.",
		"This Agreement supersedes all prior or contemporaneous communications and proposals, whether oral or written, between the parties with respect to such subject matter.",
		"When you use the Service you hereby declare and warrant that:",
		"according to your local jurisdiction you are of legal age and eligible to enter into the Agreement;",
		"according to your local jurisdiction you have no restriction to use the Service;",
		"you comply with these Terms and all applicable laws and regulations;",
		"you will only install the FoneSafe Software on devices for which you are the owner or you received a consent from such owner;",
		"you have read the Compatibility Policy and your target device meets its requirements.",
		"Except as otherwise provided, these Terms are subject to change at any time. In case of material changes affecting your use of the Service or your legal rights as a user of the Service, we’ll notify you prior to the changes’ effective date by posting a notice through the Service or sending a notification to the email provided to us by you. It is your responsibility to check periodically for any changes we make to the Terms. Your continued use of the Service after any changes have been implemented constitutes acceptance by you of such change(s).",
		"A printed version of these Terms shall be admissible in judicial or administrative proceedings based on or relating to use of the Service to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form.",
    ],
  },
  {
    title: "Account registration",
    desc: [
		"In order to use the full range of features offered by FoneSafe Service you should create a personalized account (“Account”) by providing your personal data, including your name and e-mail address. You agree to receive to the indicated address an email with details on your access to the User Space of the Service. You may change a password to the Account through the User Space.",
		"You may not transfer or assign your account. You are solely responsible for maintaining the confidentiality of your login credentials you use to sign up for the Application, and for all activities that occur under those credentials.",
		"If you know or have any reason to suspect that someone has gained access to your Account without your authorization, or your credentials have been lost, stolen, misappropriated, or otherwise compromised, please immediately notify us at support@fonesafe.net.",
		"It is your responsibility to ensure that your use of the Service and Account does not contravene applicable laws, rules, or regulations.",
		"To irreversibly terminate your Account you can use the Account settings and press “Delete your account”. Upon any termination or suspension of the Service, the monitoring of all devices on which the Software is installed shall cease and any information you have submitted through the Service or that which is related to your Account may no longer be accessed by you.",
		"In case you forgot your password to the Account you may restore the access by contacting us at support@fonesafe.net.",
		"Accounts terminated by FoneSafe for any type of abuse, including without limitation a violation of these Terms, may not be reactivated for any reason.",
    ],
  },
  {
    title: "FoneSafe Service",
    desc: [
		"FoneSafe Service shall be used only with the aim of (i) parental control of children, (ii) on a device, which is of a user’s ownership, (iii) by a user with a consent of the device owner. The Service provides several Products to be ordered by a user.",
		"You understand that you require a physical access to a device to install the Software. When installing the Software to a device you acknowledge and agree that you are a parent to your minor child.",
		"You agree that FoneSafe is not responsible or liable in any manner for the installation by you of the Software to a target device. FoneSafe does not control, monitor and/or is not aware of the purpose a user exploits the Software application and FoneSafe may not be held responsible for such use thereof. It is at a user’s risk and responsibility.",
		"Compatibility of target device. You are aware that before placing an order for subscription and installing the Software you have to check the requirements of compatibility of your target device. In case you have failed to comply with Compatibility Policy before the subscription or installation you agree that it is your responsibility and you will not be refunded. Android devices should be rooted in order to use the advanced features of FoneSafe.",
		"License to the Software Application. The Software may only be used in connection with the End User License Agreement, and rules, restrictions or documentation set forth by FoneSafe. You understand that FoneSafe, in its sole discretion, may at any time for any reason suspend or terminate any license thereunder and disable the Software and information you may already have accessed to or installed without prior notice.",
		"FoneSafe reserves the right to add or remove features or functions to the Software at any time in its sole discretion.",
		"Service updates. In an effort to improve the Service, FoneSafe may update the Software from time to time. You acknowledge and agree that FoneSafe may update the Software with or without notifying you.",
		"You acknowledge and agree that FoneSafe may request you via email or User Space to make such Software application update on a target device. In case your device was unlinked from the Software due to update of your device operating system you may address customer service at support@fonesafe.net for the relink.",
		"Data security. FoneSafe cares about the integrity and security of your personal information. FoneSafe stores in its database your personal information in encrypted way, it means FoneSafe does not have a direct access to such encrypted data. However, FoneSafe is not responsible for any third party memory leak in databases and/or DSN servers. In case of disclosure of such encrypted data FoneSafe may not be held responsible.",
		"FoneSafe stores user’s login in database. User’s password is not stored. We store only hash of the password generated by blowfish algorithm.",
		"FoneSafe does not store in its database all logs and data downloaded from a target device with the use of the Software in case of an active account for more than 3 (three) months period. Upon the expiry of this period all information gets deleted. In case of expired accounts all logs and data downloaded from a target device with the use of the Software gets deleted within 1 (one) month period.",
		"More information about your data see in our Privacy Notice.",
    ],
  },
  {
    title: "Subscription",
    desc: [
		"Under your account you may have several subscriptions. To subscribe to the Service you should place the order.",
		"Placing an Order. The Service provides several Products to be ordered by a user. The Products’ packages depend on your device, the quantity of features, which you may choose and a subscription plan.",
		"While placing the order you should complete the Billing Information and the Payment Method by providing your personal information. By providing your payment information while purchasing the Software you are aware that FoneSafe does not collect this information, it is the third-party Payment Provider that receives payment details.",
		"Subscription plans.You may choose the subscription plan for periods of 2 weeks, 1 month, 3 months, 12 months. 2 weeks subscription consists of 14 calendar days. 1 month subscription consists of 28 calendar days, this applies only to 1-, 3- and 6-months subscription plans. 12-months subscription plan lasts one calendar year (366 days). You are aware that one subscription plan allows you to use the Software for only one target device.",
		"Subscription automatically renews unless auto-renew is turned off at least 24-hours before the end of the current subscription plan. You may tick off the auto-renewal in your User Space/account during the subscription period.",
		"Following the purchase of the Software your credentials (login and password) will be sent to your email so you could enter your Account/User Space.",
		"Upon the expiration of your subscription the Service will be terminated for you.",
		"Payment details. We facilitate your payments through the third-party payment provider, namely PayPal. You acknowledge that as soon as you follow the link to pay for the Subscription Plan, you are subject to the Terms of Service, Privacy Policy and other policies of PayPal. In no case FoneSafe is responsible or liable for any personal data processed by PayPal.",
		"Please note, if your local currency is different from the currency in which the prices are quoted, your bank will apply the exchange rate applicable per the date of purchase, which is beyond our control.",
		"Service fee in amount of 1.99 usd or 1.99 eur will be charged as a part of transaction.",
		"FoneSafe reserves the right to change the prices for Subscription plans at any time, subject to providing users with adequate notice of any fee changes before they become effective. Your continued use of the Service after the effective date of such change will constitute your agreement to pay the updated prices.",
		"Discount.Please be informed that FoneSafe retains the right to grant the User a discount in the amount determined at FoneSafe sole discretion. The frequency the discount is applied to the User`s account is also determined by FoneSafe.",
		"Refunds and Returns. Please review our Refund Policy. FoneSafe Software includes a money-back guarantee if you are not satisfied for any reason. Your eligibility for a refund may depend on the Product package, subscription plan, duration since the transaction, etc.",
    ],
  },
  {
    title: "Links to third-party websites and services",
    desc: [
		"Some features of the Service may be made available or accessed in connection with third-party services and content that FoneSafe does not control. The Service may contain links or frame hyperlinks to other websites, some of which are governed by third parties. These links are provided as a convenience to you and as an additional avenue of access to the information contained therein.",
		"We have not necessarily reviewed all the information on those other websites and are not responsible for the content of those or any other website or any products or services that may be offered through those or any other website. Inclusion of links to other websites should not be viewed as an endorsement of the content of linked websites. Users use such third-party content contained therein at their own risk. We are not responsible for any losses, damages or other liabilities incurred as a result of your use of any linked website.",
		"You acknowledge that different terms of use and privacy policies may apply to your use of such third party websites, services and content.",
    ],
  },
  {
    title: "Content and intellectual property rights",
    desc: [
		"The Service is owned and controlled by FoneSafe. Unless otherwise agreed in writing, all materials in the Service, including text, graphics, information, images, designs, icons, photographs, video, sounds, music, computer code, any other materials and the intellectual property rights in such materials (collectively, the “Content”), are owned, controlled and/or licensed by FoneSafe or included in the Service with the permission of the relevant owner and are protected by copyright and other national and international intellectual property rights.",
		"Certain trademarks, trade names and logos used or displayed on the Service are registered and unregistered trademarks and trade names of FoneSafe and/or of relevant holder(s) and may not be copied, imitated or used, in whole or in part, without the prior written permission of FoneSafe or the applicable trademark holder(s). In case you download or copy the Content and other downloadable materials displayed in the Service, no right, title or interest in any downloaded or copied Content is transferred to you as a result of any such downloading or copying. You agree not to change or delete any ownership notices from materials downloaded or printed from the Service.",
		"Subject to your compliance with these Terms and provisions of End User License Agreement, FoneSafe grants you a limited, personal, non-exclusive, non-sublicensable, revocable, non-transferable, worldwide license to (i) use the Service on your personal device(s) in the manner provided for in these Terms; and (ii) access and view any Content made available in or through the Service and accessible to you.",
		"Without the prior written permission of FoneSafe, you may not frame, or make it appear that a third-party website or service is presenting or endorsing, any of the Content, or incorporate any intellectual property of FoneSafe, the Service users or any of their licensors into another website or other service.",
		"To request permission to place a link on your website to enable users to click through from your website to the Service, please send your name, address, website URL, and nature of the website to support@fonesafe.net.",
		"FoneSafe reserves the right to alter or revoke this license or your access to the Content at any time and for any reason without prior notice. FoneSafe allowing you this limited use does not constitute a waiver of any of FoneSafe’s rights to the Content. You are also advised that we are determined to enforce our intellectual property rights to the fullest extent of the law, including through civil remedies and criminal prosecution.",
    ],
  },
  {
    title: "Disclaimer of warranties",
    desc: [
		"The Service is provided on an “as is” and “as available” basis for your use, without warranties of any kind, either express or implied, including without limitation the warranties of merchantability, fitness for a particular purpose, title, non-infringement, and those arising from course of dealing or usage of trade.",
		"FoneSafe makes no warranty as to the accuracy, completeness or reliability of any Content available through, or the performance of, the Service. You are responsible for verifying any information before relying on it. Use of the Service is at your sole risk. FoneSafe does not warrant that you will be able to access or use the Service at the times or locations of your choosing; that the Service will be uninterrupted or error-free; that defects will be corrected; or that the Service is free of viruses or other harmful components.",
		"Because some states or jurisdictions do not allow the disclaimer of implied warranties, the foregoing disclaimer may not apply to you.",
    ],
  },
  {
    title: "Limitation of liability",
    desc: [
		"To the maximum extent permitted by law, FoneSafe, its affiliates, licensors and business partners (collectively, the “related parties”) disclaim all liability, whether based in contract, tort (including negligence), strict liability or otherwise, and further disclaims all losses, including without limitation direct, indirect, incidental, consequential, or special damages arising out of or in any way connected with access to or use of the Service, even if FoneSafe and/ or related parties have been advised of the possibility of such damages.",
		"You agree that FoneSafe is not responsible or liable in any manner for the installation by you of the Software to a target device. FoneSafe does not control, monitor and/or is not aware of the purpose a user exploits the Software application and FoneSafe may not be held responsible for such use thereof. It is at a user’s risk and responsibility.",
		"Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, the liability of FoneSafe and its affiliates shall be limited to the fullest extent permitted by law. Please refer to your local laws for any such prohibitions.",
		"If any part of the warranty disclaimers or limitations of liability is found to be invalid or unenforceable for any reason or if we are otherwise found to be liable to you in any manner, then our aggregate liability for all claims under such circumstances for liabilities, shall not exceed the total amount that you paid to FoneSafe during the six (6) months prior to the time the cause of action giving rise to liability arose.",
    ],
  },
  {
    title: "Indemnification",
    desc: [
		"You agree to defend, indemnify and hold harmless FoneSafe, its officers, directors, employees, business partners and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorneys’ fees) arising from: (i) any breach by you of any of these Terms, (ii) your use/misuse of materials or features of the Service or (iii) a violation by you of applicable law or any agreement or terms with a third party to which you are subject.",
		"You also agree to reimburse FoneSafe for any damage, loss, cost or expense FoneSafe incurs (including fees or costs of attorneys, accountants, professional advisors, and other experts incurred in connection with the defense or settlement of the foregoing) because of your use of the Service for any unlawful or prohibited purpose.",
		"FoneSafe reserves the right to handle our legal defense however we see fit, including instances when you are indemnifying us. Therefore, you agree to cooperate with us so we execute our strategy.",
    ],
  },
  {
    title: "Applicable law and jurisdictions",
    desc: [
		"Except as otherwise set forth in these Terms or any policies, these Terms shall be exclusively governed by and construed in accordance with the laws of United Kingdom, excluding its rules on conflicts of laws.",
		"These Terms do not limit your rights under the laws of your state or country. If any material or feature of the Service, or your use of the Service, is contrary to the laws of the place where you are when you access it, the Service is not intended for you, and we ask you not to use the Service. You are responsible for informing yourself of the laws of your jurisdiction and complying with them.",
		"Except where prohibited by applicable law, and without limitation to any statutory rights for consumers, you agree that any dispute, conflict, claim or controversy directly or indirectly arising out of or broadly in connection with or relating to the Service or these Terms, including, without limitation, those relating to its validity, its construction or its enforceability (any “Dispute”) shall be resolved by means of amicable negotiations directly with FoneSafe team in accordance with the principles of good faith and cooperation. If the consensus may not be reached by the negotiations, the Dispute shall be settled exclusively in the state courts of United Kingdom, subject to its jurisdiction rules, with applicable substantive law of United Kingdom.",
		"All claims shall be brought within one (1) year after the claim arises, except to the extent a longer period is required by applicable law.",
    ],
  },
  {
    title: "Electronic communications",
    desc: [
		"By using the Service, you understand and agree that we may send you via email or the Service the communications or data from FoneSafe, including but not limited to (i) notices about the Service and Products used or requested by you; (ii) notifications about changes to your Account; (iii) updates and information or materials regarding transactions in which you are involved via use of the Service.",
		"You understand that we may use your personal information to contact you with newsletters, marketing or promotional materials and other information related to the Service. We will give you the opportunity to unsubscribe from receiving these commercial emails from us by following the instructions provided in such message(s).",
    ],
  },
  {
    title: "Term and Termination",
    desc: [
		"Termination by FoneSafe. We reserve the right, at any time at our sole discretion, to: modify, suspend or discontinue the Service, content, feature or offers through the Service, with or without notice; and/or offer or cancel opportunities to some or all users of the Service. You agree that we shall not be liable to you or to any third party should any of the foregoing occur with respect to the Service.",
		"FoneSafe reserves the right to take whatever lawful actions it may deem appropriate in response to actual or suspected violations of these Terms, including, without limitation, the suspension or termination of your access and/or account. FoneSafe may cooperate with legal authorities and/or third parties in the investigation of any suspected or alleged crime or civil wrong.",
		"Termination by you. You may terminate this agreement with FoneSafe at any time by deleting your Account as set forth in section “Account registration” herein and no longer accessing or using the Service.",
		"If you close your Account, all licenses granted in these Terms will immediately terminate. We will delete all data associated with your Account and activity from our servers without liability or obligation to you.",
    ],
  },
  {
    title: "Other terms",
    desc: [
		"Assignment. FoneSafe may assign these Terms, in whole or in part, to any person or entity at any time with or without your consent. You may not assign the Terms without FoneSafe’s prior written consent, and any unauthorized assignment by you shall be null and void.",
		"Severability. If any part of these Terms is determined to be invalid or unenforceable, then that portion shall be severed, and the remainder of the Terms shall be given full force and effect.",
    ],
  },
  {
    title: "Questions and contact information",
    desc: [
		"If you have any questions or comments about these Terms of Service or FoneSafe Service, please contact us by:",
		"Emailing us at: support@fonesafe.net.",
    ],
  },
];

const TermsOfService = () => {
  return (
    <div style={styles.container}>
      <Header />
      <Banner text={title} />
      <div style={{ ...middleStyle, ...styles.content }}>
        {content.map((data, index) => (
          <Content {...data} key={index} />
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default TermsOfService;