import React from 'react';
import FeatureItem from '../../../../components/Feature';
import { middleStyle } from '../../../../constants';
import styles from './index.styles';

const items = [
	{
		title: 'App Activity',
		features: [
			'Monitor how much time you spend using each app on your smartphone.',
			'Track all the processes running on your smartphone at any given time',
			'Detect and block any suspicious application activities',
		],
	},
	{
		title: 'Suspicious Wifi Protection',
		features: [
			'Stop automated Wifi connections',
			'Auto-scan wifi networks for security',
			'Automatically block suspicious networks',
		],
	},
	{
		title: 'Spyware Protection',
		features: [
			'Detect and prevent all new and existing spyware applications',
			'Determine spyware installation date/time',
			'Identify and restrict potentially harmful sources',
		],
	},
	{
		title: 'Advanced Apps Blocking',
		features: [
			'Prevent app reinstallation and relaunch without approval',
			'Prevent the installation of apps from specified developers on your mobile device.',
			"Get regular updates on the application's status and data protection",
		],
	},
	{
		title: 'Advanced Location Tracking',
		features: [
			'Receive alerts about suspicious location markers on your accounts.',
			'Have all location data preserved in your FoneSafe account.',
			'Check the last known location of a lost or stolen phone',
		],
	},
	{
		title: 'Browser Security Protection',
		features: [
			'Examine the websites for malware and other possible dangers',
			'Track web traffic through various websites',
			'Configure website blocking based on specific security parameters',
		],
	},
];

const Item = ({
	title,
	features,
	index,
}: {
	title: string;
	features: string[];
	index: number;
}) => {
	return (
		<div
			style={{ ...styles.item, ...(index % 3 === 2 && styles.noRightMargin) }}
		>
			<p style={styles.itemTitle}>{title}</p>
			<div>
				{features.map((feature, index) => (
					<FeatureItem
						allowWrap={true}
						style={styles.feature}
						text={feature}
						key={index}
					></FeatureItem>
				))}
			</div>
		</div>
	);
};

const Feature = () => {
	return (
		<div style={styles.container}>
			<h2 style={styles.h2}>Features to Supercharge your Android Experience</h2>
			<div style={{ ...middleStyle, ...styles.wrapper }}>
				{items.map((item, index) => (
					<Item {...item} index={index} key={item.title}></Item>
				))}
			</div>
		</div>
	);
};

export default Feature;
