import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Faq from './pages/Faq';
import Product from './pages/Product';
import Pricing from './pages/Pricing';
import ContactUs from './pages/Privacy/contactUs';
import CookieNotice from './pages/Privacy/cookieNotice';
import Eula from './pages/Privacy/eula';
import PrivacyNotice from './pages/Privacy/privacyNotice';
import RecurringPolicy from './pages/Privacy/recurringPolicy';
import RefundPolicy from './pages/Privacy/refundPolicy';
import TermsOfService from './pages/Privacy/termsOfService';
import Payment from './pages/Payment';
import Middle from './pages/Middle';

const App = () => (
	<Routes>
		<Route path="/" element={<Home />} />
		<Route path="product" element={<Product />} />
		<Route path="pricing" element={<Pricing />} />
		<Route path="faq" element={<Faq />} />
		<Route path="contact-us" element={<ContactUs />} />
		<Route path="cookie-notice" element={<CookieNotice />} />
		<Route path="eula" element={<Eula />} />
		<Route path="privacy-notice" element={<PrivacyNotice />} />
		<Route path="recurring-policy" element={<RecurringPolicy />} />
		<Route path="refund-policy" element={<RefundPolicy />} />
		<Route path="terms-of-service" element={<TermsOfService />} />
		<Route path="cart/:id" element={<Middle />} />
		<Route path="payment/:id" element={<Payment />} />
	</Routes>
);

export default App;
