/** @format */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from '../strings/i18n/en.json';
import ja from '../strings/i18n/ja.json';
import ru from '../strings/i18n/ru.json';
import it from '../strings/i18n/it.json';
import fr from '../strings/i18n/fr.json';
import pt_br from '../strings/i18n/pt-br.json';
import es from '../strings/i18n/es.json';
import de from '../strings/i18n/de.json';

// 每个国家对应的语言 - MS
// const transifyFileMap = {
//   id: ['en', 'id'],
//   my: ['en', 'ms', 'zh-my'],
//   ph: ['en', 'ph', 'zh-Hans'],
//   sg: ['en', 'zh-Hans'],
//   tw: ['en', 'zh-Hant', 'zh-Hans'],
//   th: ['en', 'th'],
//   vn: ['en', 'vi'],
// }

export enum LanguageMapKeys {
	en = 'en',
	es = 'es',
	'pt-br' = 'pt',
	ja = 'ja',
	de = 'de',
	ru = 'ru',
	it = 'it',
	fr = 'fr',
}

const resources = {
	[LanguageMapKeys.en]: {
		translation: en,
	},
	[LanguageMapKeys.de]: {
		translation: de,
	},
	[LanguageMapKeys['pt-br']]: {
		translation: pt_br,
	},
	[LanguageMapKeys.fr]: {
		translation: fr,
	},
	[LanguageMapKeys.es]: {
		translation: es,
	},
	[LanguageMapKeys.ja]: {
		translation: ja,
	},
	[LanguageMapKeys.ru]: {
		translation: ru,
	},
	[LanguageMapKeys.it]: {
		translation: it,
	},
};

export const initLanguage = (language: LanguageMapKeys) => {
	i18n.use(initReactI18next).init({
		resources,
		lng: LanguageMapKeys[language] || LanguageMapKeys.en,
		fallbackLng: LanguageMapKeys.en,
		interpolation: {
			prefix: '{',
			suffix: '}',
		},
	});
};

export const t = i18n.t.bind(i18n);

export default i18n;
