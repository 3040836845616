import React from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Banner from './components/Banner';
import Summary from './components/Summary';
import Feature from './components/Feature';
import Banner1 from './components/Banner1';
import Banner2 from './components/Banner2';
import Step from './components/Step';
import styles from './index.styles';

const Product = () => {
	return (
		<div style={styles.container}>
			<Header />
			<Banner></Banner>
			<Summary></Summary>
			<Feature></Feature>
			<Step></Step>
			<Banner2></Banner2>
			<Banner1></Banner1>
			<Footer />
		</div>
	);
};

export default Product;
