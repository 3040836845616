import { convert } from '../../../../constants';

const styles = {
	container: {
		height: convert(128),
		marginTop: convert(-64),
	},
	inner: {
		padding: `0 ${convert(50)}`,
		position: 'relative' as 'relative',
		// top: '-50%',
	},
	wrapper: {
		display: 'flex',
		padding: `0 ${convert(32)}`,
		alignItems: 'center',
		justifyContent: 'space-between',
		width: convert(1200),
		height: convert(128),
		background: '#FFFFFF',
		boxShadow: '0px 10px 20px rgba(3, 63, 130, 0.1)',
		borderRadius: '16px',
	},
	feature: {
		width: convert(266),
		margin: 0,
		height: 'auto',
		flex: 1,
		alignItems: 'flex-start',
	},
};

export default styles;
