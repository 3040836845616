import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import styles from "./index.styles";
import Banner from "./components/Banner";
import { middleStyle } from "../../constants";
import Content from "./components/Content";

const title = "Privacy Notice";

const content = [
  {
    title: "",
    desc: [
      "This Privacy Notice is here to help you to understand how your personal data is used when you're using FoneSafe Website located at fonesafe.net",

      "We have written two versions: a full-text version that is legally binding and also section summaries, which will hopefully make the legal language sound more understandable. You will need to read to this Privacy Notice and agree to our Terms of Service before you use FoneSafe Website or Service for the first time. Please, do read the Terms of Service carefully, since this Notice is a part of them.",

      "If you have any questions you can contact us at support@fonesafe.net.",

      "Quick Summary of our Privacy Notice:",
      "This Notice explains what data is collected in connection with the Site and the Service.",
      "It also explains how we use that data, where we store it, and how we protect it.",
      "In short:",
      "in order for you to use our Site or use our Service, we need to process some of your data. Should we need to process your data for any other purpose than offering you our Site and Service, we will always ask you for your consent in advance.",
      "We will not share your data for the third party advertising purposes.",
      "Finally, it explains your rights in relation to your personal data.",
      "The main purpose of the collection of your data is to provide you with our Service. Also, we use your data for other reasons such as:",
      "Safety and security;",
      "Customer support;",
      "Communications from FoneSafe;",
      "Compliance with legal obligations;",
      "Research and development.",
    ],
  },
];

const PrivacyNotice = () => {
  return (
    <div style={styles.container}>
      <Header />
      <Banner text={title} />
      <div style={{ ...middleStyle, ...styles.content }}>
        {content.map((data, index) => (
          <Content {...data} key={index} />
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyNotice;
