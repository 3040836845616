import React from 'react';
import { Link } from 'react-router-dom';
import styles from './index.styles';

const banner1 = require('../../../../assets/banner5.png');
const selected = require('../../../../assets/selected@2x.png');

const FeatureItem = ({ text }: { text: string }) => {
	return (
		<div style={styles.featureItem}>
			<img style={styles.selected} src={selected} alt="" />
			<span style={styles.featureText}>{text}</span>
		</div>
	);
};

const features = [
	'All-round android protection with a single app',
	'24/7 customer support',
	'Professional technical and security help',
];

const Banner = () => {
	return (
		<div
			style={{
				...styles.container,
			}}
		>
			<img style={styles.bannerImg} src={banner1} alt="" />

			<div style={styles.left}>
				<h1 style={styles.title}>Try FoneSafe from $39.99/month</h1>
				<p style={styles.desc}>Automatic and fully supported</p>
				<div style={styles.featureContainer}>
					{features.map((feature) => (
						<FeatureItem text={feature} key={feature} />
					))}
				</div>
				<div>
					<Link to="/pricing">
						<button style={styles.button}>Check Plans Now</button>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default Banner;
