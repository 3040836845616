import { convert } from '../../../../constants';

const styles = {
	title: {
		fontWeight: '800',
		fontSize: convert(40),
		lineHeight: convert(56),
		/* or 140% */
		textAlign: 'center' as 'center',
		/* 中性色/202124-标题色 */
		color: '#202124',
		width: convert(900),
	},
	container: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column' as 'column',
		paddingTop: convert(96),
	},
	text: {
		color: '#202124',
		fontSize: convert(24),
		lineHeight: convert(40),
		width: convert(900),
		paddingTop: convert(32),
		fontDamily: 'Lora',
		fontStyle: 'italic',
		textAlign: 'center' as 'center',
	},
	swiperSlide: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column' as 'column',
		width: convert(900),
		paddingBottom: convert(50),
	},
	author: {
		fontSize: convert(16),
		lineHeight: convert(24),
	},
	swiperContainer: {
		marginBottom: convert(96),
	},
	swiperInner: {
		width: convert(900),
		margin: `0 ${convert(94)}`,
		// overflow: 'hidden',
	},
	swiperInnerContainer: {
		width: convert(900),
		// display: 'flex',
		// flexWrap: 'nowrap' as 'nowrap',
	},
	swiperTop: {
		display: 'flex',
		justifyContent: 'center',
	},
	swiperLeft: {
		cursor: 'pointer',
	},
	swiperLeftImg: {
		width: '56px',
	},
	swiperRight: {
		cursor: 'pointer',
		width: '56px',
		display: 'flex',
		justifyContent: 'center',
	},
	swiperRightImg: {
		width: '100px',
		height: '100px',
	},
};

export default styles;
