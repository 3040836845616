import React from 'react';
import { Link } from 'react-router-dom';
import { middleStyle } from '../../../../constants';
import styles from './index.styles';

const Banner = () => {
	return (
		<div
			style={{
				...styles.container,
			}}
		>
			<div style={{ ...middleStyle, ...styles.wrapper }}>
				<div style={styles.left}>
					<h2 style={styles.title}>World-class Technical Customer Support</h2>
					<Link to="/pricing">
						<button style={styles.button}>Check Out Prices</button>
					</Link>
				</div>
				<div style={styles.right}>
					<p style={styles.text}>
						It all began with our technical specialists constantly debating with
						their marketing department counterparts about Android devices being
						far more flexible and having more capabilities than iOS smartphones.
						"You can download programs from many marketplaces, customize your
						layout, and set up whatever music you like. Additionally, it extends
						battery life and is far less expensive," they stated.
					</p>
					<p style={styles.text}>
						And they are entirely correct. However, as entertaining as Android
						smartphones are, so too are they susceptible to security risks.
						Therefore, we decided to design a user-friendly security Android
						app, add Android compatibility for our iOS-loving buddies, and test
						this move.
					</p>
					<p style={styles.text}>
						We have a package that includes technical support and even a very
						special offer for individuals who require assistance with their
						smartphones, laptops, PCs, or basic online security questions.
					</p>
				</div>
			</div>
		</div>
	);
};

export default Banner;
