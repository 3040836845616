import React from 'react';
import { Link } from 'react-router-dom';
import { middleStyle } from '../../../../constants';
import styles from './index.styles';

const banner1 = require('../../../../assets/banner4.png');

const Banner = () => {
	return (
		<div
			style={{
				...styles.container,
			}}
		>
			<div style={{ ...middleStyle, ...styles.wrapper }}>
				<img style={styles.bannerImg} src={banner1} alt="" />

				<div style={styles.left}>
					<h2 style={styles.title}>
						Trust the professionals with your Android cybersecurity issues
					</h2>
					<Link to="/pricing">
						<button style={styles.button}>Check Plans</button>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default Banner;
